import { useEffect } from "react";
import { logoutUser } from "../../api";

export default function LogoutPage() {
  useEffect(() => {
    async function fetchData() {
      await logoutUser();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
      window.location.reload();
    }
    fetchData();
  }, []);

  return <div></div>;
}

import { useContext } from "react";
import "./verification-email.css";
import PrimaryButton from "../../components/Button/";
import { Formik, Form } from "formik";
import Input from "../../components/Input/";
import { useHistory } from "react-router-dom";
import { verifyEmail } from "../../api";
import { toast } from "react-toastify";
import { LangContext } from "../../context/LangContext";
import { Redirect } from "react-router-dom";
import { useCurrentUser } from "../../context/UserContext";

const queryString = require("query-string");

const VerificationEmailPage = () => {
  const { token } = queryString.parse(window.location.search);
  const { currentUser } = useCurrentUser();

  const { dictionary } = useContext(LangContext);
  const history = useHistory();
  if (!token) return <Redirect to="/verify" />;
  return (
    <div className="center">
      <Formik
        initialValues={{ password: "", token }}
        onSubmit={async (data) => {
          const response = await verifyEmail(data);
          if (response.status === 200) {
            toast.success(dictionary["email.verified"]);
            history.push("/");
            if (currentUser) window.location.reload();
          }
          if (response.data.errors) {
            if (response.data.errors.password)
              toast.error(response.data.errors.password[0]);
            if (response.data.errors.token)
              toast.error(response.data.errors.token[0]);
          }
        }}
      >
        {({ errors, values, touched, handleChange }) => (
          <Form>
            <div className="verification-email-form">
              <h4>{dictionary["enter.password.to.verify.email"]}</h4>
              <Input
                name="password"
                type="password"
                label={dictionary.password}
                errors={errors.password}
                touched={touched.password}
                value={values.password}
                onChange={handleChange}
                required
              />
              <PrimaryButton label={dictionary.submit} type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerificationEmailPage;

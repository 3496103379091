import { useContext } from "react";
import Container from "../../components/Container";
import PrimaryButton from "../../components/Button";
import { LangContext } from "../../context/LangContext";
import { useHistory } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import useWindowDimensions from "../../utils/useWindowDimensions";

const PayoutTransactionsPage = () => {
  const { width } = useWindowDimensions();
  const { dictionary } = useContext(LangContext);
  const history = useHistory();

  return (
    <Container size="sm">
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns={width <= 768 ? "1fr" : "1fr 1fr"}
      >
        <PrimaryButton
          label={dictionary["credits"]}
          onClick={() => history.push("/payout")}
          className="profile-button"
        />
        <PrimaryButton
          label={dictionary["transactions"]}
          onClick={() => history.push("/payouts/transactions")}
          className="profile-button active"
        />
      </Box>
    </Container>
  );
};

export default PayoutTransactionsPage;

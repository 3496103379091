import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { LangContext } from "../../context/LangContext";
import { useCurrentUser } from "../../context/UserContext";
import LanguageSelector from "../LanguageSelector";
import { ExpandedContext } from "../../context/ExpandedContext";
import Notifications from "../Notifications";

const Header = ({ setExpandedNotification, onOpen }) => {
  const { expandedChange: setMinimized, expanded: minimized } =
    useContext(ExpandedContext);

  const { currentUser } = useCurrentUser();
  const { dictionary } = useContext(LangContext);
  const [expanded, setExpanded] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <Box
      zIndex={2}
      borderBottom="1px solid rgba(0, 0, 0, 0.6)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Button
          backgroundColor="transparent"
          my="5px"
          mx="5px"
          borderColor="transparent"
          border="0px"
          variant="unstyled"
          p="0px"
          _focus={{
            bg: "transparent",

            borderColor: "transparent",
          }}
          className="hamburger-menu"
          onClick={() => setMinimized(!minimized)}
        >
          {!minimized && (
            <Box display="inline" mr="0.2em">
              <FontAwesomeIcon
                color="#ADC439"
                size="sm"
                icon="fa fa-chevron-left"
              ></FontAwesomeIcon>
            </Box>
          )}
          <FontAwesomeIcon
            size="lg"
            color="#7F7F7B"
            icon="fa fa-bars"
          ></FontAwesomeIcon>
          {minimized && (
            <Box display="inline" ml="0.2em">
              <FontAwesomeIcon
                color="#ADC439"
                size="sm"
                icon="fa fa-chevron-right"
              ></FontAwesomeIcon>
            </Box>
          )}
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        {currentUser?.email_verified_at ? (
          <Notifications
            setExpandedNotification={setExpandedNotification}
            onOpen={onOpen}
          />
        ) : null}
        <Box
          width="2px"
          height="20px"
          backgroundColor="#afcb2d"
          mx="0.2em"
        ></Box>
        <Button
          width={width <= 768 ? "20vw" : "max-content"}
          backgroundColor="transparent"
          _hover={{
            background: "transparent",
          }}
          boxSizing="border-box"
          _focus={{ boxShadow: "none" }}
          onClick={() => setExpanded(!expanded)}
          className={` ${expanded ? "expanded" : ""}`}
        >
          <FontAwesomeIcon icon="user" fontSize={width <= 768 ? 12 : 18} />
          {currentUser && width >= 768 && (
            <p>
              {currentUser.name} {currentUser.surname}
            </p>
          )}
          {expanded ? (
            <FontAwesomeIcon
              fontSize={width <= 768 ? 16 : 22}
              icon="angle-up"
            />
          ) : (
            <FontAwesomeIcon
              fontSize={width <= 768 ? 16 : 22}
              icon="angle-down"
            />
          )}
          {expanded && (
            <Box className="user-buttons">
              <Link to="/profile" className="user-button">
                {dictionary["profile"]}
              </Link>
              <Link to="/profile/edit" className="user-button">
                {dictionary["edit.profile"]}
              </Link>
              <Link to="/logout" className="user-button">
                {dictionary["logout"]}
              </Link>
            </Box>
          )}
        </Button>
        <LanguageSelector absolute={false}></LanguageSelector>
      </Box>
    </Box>
  );
};

export default Header;

import { Switch, Redirect, Route } from "react-router-dom";
import React from "react";
import LogoutPage from "../pages/logout/";
import VerifyEmail from "../pages/verify";
import ProtectedRoute from "../components/ProtectedRoute";
import VerificationEmailPage from "../pages/verification-email";

export default function EmailVerification() {
  return (
    <Switch>
      <Route path="/email/verify" component={VerificationEmailPage} />
      <ProtectedRoute path="/verify" component={VerifyEmail} />
      <ProtectedRoute path="/logout" component={LogoutPage} />

      <Redirect to="/verify" />
    </Switch>
  );
}

import React, { useEffect, useState, useContext } from "react";

import iosShareIconSrc from "../../assets/images/ios-share-icon.png";
import { Button } from "@chakra-ui/button";
import { Box, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LangContext } from "../../context/LangContext";

export default function IOSInstallPrompt() {
  const { dictionary } = useContext(LangContext);

  const [isCanceled, setIsCanceled] = useState(false);
  const disableAlert = () => setIsCanceled(true);
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;
  const isAlertCanceled = () =>
    JSON.parse(localStorage.getItem("_ios-installAlert"));

  useEffect(() => {
    localStorage.setItem("_ios-installAlert", isCanceled);
  }, [isCanceled]);

  if (isCanceled) return null;

  return (
    isIos() &&
    !isInStandaloneMode() &&
    !isAlertCanceled() && (
      <Button
        onClick={disableAlert}
        my={1}
        mx={2}
        position="fixed"
        zIndex="9999"
        bottom="60px"
        padding="0.1em 1em"
        width="calc(100% - 20px)"
        backgroundColor="#a9da3a"
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-around"
          alignItems="center"
          position="relative"
        >
          <FontAwesomeIcon size="2x" icon="fas fa-window-close" />

          <Text>{dictionary["add.to.home"]}</Text>
          <Image
            transform="scale(0.7)"
            width="25px"
            height="37px"
            src={iosShareIconSrc}
            alt="share icon"
          />
        </Box>
        <Box
          top="70px"
          width="20px"
          height="20px"
          position="absolute"
          background="transparent"
          borderLeft="7px solid #a9da3a"
          borderBottom="7px solid #a9da3a"
          transform="rotate(-45deg)"
          left="calc(50% - 10px)"
        />
      </Button>
    )
  );
}

import { useState, useContext } from "react";
import Container from "../../components/Container/";
import { useQuery } from "react-query";
import { getLogs } from "../../api";
import "./logs.css";
import Pagination from "../../components/Pagination";
import PrimaryButton from "../../components/Button";
import { LangContext } from "../../context/LangContext";
import Loading from "../../components/Loading";

const Logs = () => {
  const { dictionary } = useContext(LangContext);

  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = useState();

  const { data, isLoading, isError } = useQuery(
    ["logs", page],
    () => getLogs(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (isLoading) return <Loading />;

  if (isError) return "Error...";

  const { data: logs, next_page_url: nextPage, prev_page_url: prevPage } = data;

  //Get browser name from user agent string
  const getBrowser = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  };
  return (
    <Container size="sm">
      <div className="logs">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{dictionary["name"]}</th>
              <th>{dictionary["description"]}</th>
              <th>{dictionary["action"]}</th>

              <th>{dictionary["updated.at"]}</th>
            </tr>
          </thead>

          <tbody>
            {/* Log data */}
            {logs &&
              logs.length > 0 &&
              logs.map((log, index) => (
                <tr key={index}>
                  <td data-label="#">{data.from + index}</td>
                  <td data-label="Name">{log.log_name}</td>
                  <td data-label="Description">{log.description}</td>
                  <td data-label="Action">
                    <PrimaryButton
                      onClick={() => {
                        expanded === index ? setExpanded() : setExpanded(index);
                      }}
                      className="primary-button"
                      label={dictionary["open.details"]}
                    ></PrimaryButton>
                  </td>

                  <td data-label="Updated At">{log.updated_at}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <Pagination
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          currentPage={data.current_page}
        ></Pagination>
      </div>

      {/* Modals for details */}
      {logs &&
        logs.length > 0 &&
        logs.map((log, index) => (
          <div
            key={index}
            className="info"
            style={{ display: index === expanded ? "flex" : "none" }}
          >
            <div className="log-more-info-inner modal-content">
              <PrimaryButton
                onClick={() => {
                  expanded === index ? setExpanded() : setExpanded(index);
                }}
                className="primary-button"
                label="Close"
              ></PrimaryButton>
              <table className="table">
                <thead>
                  <tr>
                    <th>{dictionary["IP"]}</th>
                    <th>{dictionary["browser"]}</th>
                    <th>{dictionary["action"]}</th>
                  </tr>
                </thead>
                <tbody>
                  <td data-label="IP">
                    {log.properties.ip
                      ? log.properties.ip
                      : dictionary["no.ip.address"]}
                  </td>
                  <td data-label="Browser">
                    {log.properties.userAgent
                      ? getBrowser(log.properties.userAgent)
                      : dictionary["unknown"]}
                  </td>
                  <td>{log.properties.action}</td>
                </tbody>
              </table>

              {log.properties.attributes && (
                <div className="old">
                  <p className="column-title">Old</p>
                  <hr className="divide" />
                  {log.properties.old &&
                    log.properties.attributes &&
                    Object.keys(log.properties.old).map((key, i) => (
                      <LogInfoItem
                        key={`${key}-${i}`}
                        label={key.split("_").join(" ")}
                        value={Object.values(log.properties.old)[i]}
                        color={
                          Object.values(log.properties.old)[i] !==
                          Object.values(log.properties.attributes)[i]
                            ? "red"
                            : ""
                        }
                      />
                    ))}
                </div>
              )}
              {log.properties.attributes && (
                <div className="new">
                  <p className="column-title">{log.description}</p>
                  <hr className="divide" />
                  {log.properties.attributes &&
                    log.properties.old &&
                    Object.keys(log.properties.attributes).map((key, i) => (
                      <LogInfoItem
                        key={`${key}-${i}`}
                        label={key.split("_").join(" ")}
                        value={Object.values(log.properties.attributes)[i]}
                        color={
                          Object.values(log.properties.old)[i] !==
                          Object.values(log.properties.attributes)[i]
                            ? "green"
                            : ""
                        }
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        ))}
    </Container>
  );
};

const LogInfoItem = ({ label, value, color }) => {
  return (
    <div className="log-info-item">
      <p className="label">{label}</p>
      <p className={"value " + color}>{value || "Empty"}</p>
    </div>
  );
};

export default Logs;

import { useState, useContext } from "react";
import Container from "../../components/Container/";
import { useQuery } from "react-query";
import { getReferralUsers } from "../../api";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination";
import PrimaryButton from "../../components/Button";
import { Box } from "@chakra-ui/layout";
import { LangContext } from "../../context/LangContext";
import { Input } from "@chakra-ui/input";
import { Select } from "@chakra-ui/select";
import Loading from "../../components/Loading";
import useWindowDimensions from "../../utils/useWindowDimensions";

const ReferralUsersPage = () => {
  const { dictionary } = useContext(LangContext);
  const { width } = useWindowDimensions();
  const [page, setPage] = useState(1);
  const [emailFilter, setEmailFilter] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [verified, setVerified] = useState(null);
  const [paid, setPaid] = useState(null);
  const { data, isLoading, isError } = useQuery(
    ["referralUsers", page, emailSearch, paid, verified],
    () => getReferralUsers(page, emailSearch, paid, verified),
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );
  const history = useHistory();

  const [expanded, setExpanded] = useState();
  const [paypalExpanded, setPaypalExpanded] = useState();

  if (isLoading) return <Loading />;

  if (isError) {
    return "Error...";
  }

  const {
    data: users,
    next_page_url: nextPage,
    prev_page_url: prevPage,
  } = data;
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailSearch(emailFilter);
  };

  return (
    <Container size="sm">
      <div className="logs">
        <Box
          display="grid"
          gridTemplateColumns={width <= 768 ? "1fr" : "1fr 1fr 1fr"}
          justifyContent="center"
          justifyItems="center"
          alignItems="center"
          marginBottom="10px"
        >
          <form
            style={{
              display: "flex",
              flexDirection: width <= 768 ? "column" : "row",
              alignItems: "center",
            }}
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              name="email"
              onChange={(e) => {
                setEmailFilter(e.target.value);
                !e.target.value && setEmailSearch("");
              }}
              value={emailFilter}
              placeholder="example@example.com"
            ></Input>
            <PrimaryButton
              label={dictionary["search"]}
              type="submit"
              className="primary-button"
            ></PrimaryButton>
          </form>

          <Select
            width="auto"
            value={verified}
            onChange={(e) => setVerified(e.target.value)}
            name="email_verified"
            id="cars"
          >
            <option value="">{dictionary["verification"]}</option>
            <option value="true">{dictionary["verified"]}</option>
            <option value="false">{dictionary["unverified"]}</option>
          </Select>

          <Select
            width="auto"
            value={paid}
            onChange={(e) => setPaid(e.target.value)}
            name="paid"
            id="paid"
          >
            <option value="">{dictionary["payment"]}</option>
            <option value="true">{dictionary["paid"]}</option>
            <option value="false">{dictionary["unpaid"]}</option>
          </Select>
        </Box>

        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{dictionary["name"]}</th>
              <th>{dictionary["surname"]}</th>
              <th>{dictionary["email"]}</th>
              <th>{dictionary["verification"]}</th>
              <th>{dictionary["payment"]}</th>
              <th>{dictionary["user.page"]}</th>
            </tr>
          </thead>

          <tbody>
            {users && users.length > 0 ? (
              users.map((user, index) => (
                <tr key={index}>
                  <td data-label="#">{data.from + index}</td>
                  <td data-label={dictionary["name"]}>{user.name}</td>
                  <td data-label={dictionary["surname"]}>{user.surname}</td>
                  <td data-label={dictionary["email"]}>{user.email}</td>
                  <td data-label={dictionary["verification"]}>
                    {user.email_verified_at ? (
                      <span style={{ color: "green" }}>
                        {dictionary["true"]}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {dictionary["false"]}
                      </span>
                    )}
                  </td>
                  <td data-label="Paypal">
                    {user.subscriptions.length ||
                    user.paypal_subscriptions.length ? (
                      <PrimaryButton
                        onClick={() => {
                          expanded === index
                            ? setExpanded()
                            : setExpanded(index);
                        }}
                        className="primary-button"
                        label={dictionary["open.details"]}
                      ></PrimaryButton>
                    ) : (
                      dictionary["no.subscription"]
                    )}
                  </td>

                  <td data-label={dictionary["user.page"]}>
                    {user.subscriptions ? (
                      <PrimaryButton
                        onClick={() => {
                          history.push("/referral/users/" + user.id);
                        }}
                        className="primary-button"
                        label={dictionary["open.details"]}
                      ></PrimaryButton>
                    ) : (
                      dictionary["no.subscription"]
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr style={{ textAlign: "center" }}>
                {dictionary["no.results"]}
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}
        <Pagination
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          currentPage={data.current_page}
        ></Pagination>
      </div>
      {users &&
        users.length > 0 &&
        users.map(
          (log, index) =>
            log.paypal_subscriptions && (
              <div
                key={index}
                className="info"
                style={{ display: index === paypalExpanded ? "flex" : "none" }}
              >
                <div
                  className="log-more-info-inner modal-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <PrimaryButton
                    onClick={() => {
                      paypalExpanded === index
                        ? setPaypalExpanded()
                        : setPaypalExpanded(index);
                    }}
                    className="primary-button"
                    label={dictionary["close"]}
                  ></PrimaryButton>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Plan Id</th>
                        <th>Quantity</th>
                        <th>Trial ends</th>
                        <th>Ends</th>
                      </tr>
                    </thead>

                    <tbody>
                      {log &&
                        log.paypal_subscriptions.length > 0 &&
                        log.paypal_subscriptions.map((user, index) => (
                          <tr key={index}>
                            <td data-label="#">{1 + index}</td>
                            <td data-label="Name">{user.name}</td>
                            <td data-label="Status">{user.paypal_status}</td>

                            <td data-label="Plan Id">{user.paypal_plan_id}</td>
                            <td data-label="Quantity">{user.quantity}</td>
                            <td data-label="Trial ends">
                              {user.trial_ends_at || "No Trial"}
                            </td>
                            <td data-label="Ends">
                              {user.ends_at || "No end date"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
        )}

      {/* Modals for details stripe */}
      {users &&
        users.length > 0 &&
        users.map(
          (log, index) =>
            log.subscriptions && (
              <div
                key={index}
                className="info"
                style={{ display: index === expanded ? "flex" : "none" }}
              >
                <div
                  className="log-more-info-inner modal-content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <PrimaryButton
                    onClick={() => {
                      expanded === index ? setExpanded() : setExpanded(index);
                    }}
                    className="primary-button"
                    label={dictionary["close"]}
                  ></PrimaryButton>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Trial ends</th>
                        <th>Ends</th>
                      </tr>
                    </thead>

                    <tbody>
                      {log &&
                        log.subscriptions.length > 0 &&
                        log.subscriptions.map((user, index) => (
                          <tr key={index}>
                            <td data-label="#">{index + 1}</td>
                            <td data-label="Name">{user.name}</td>
                            <td data-label="Status">{user.stripe_status}</td>

                            <td data-label="Created">{user.created_at}</td>
                            <td data-label="Trial ends">
                              {user.trial_ends_at || "No Trial"}
                            </td>
                            <td data-label="Ends">
                              {user.ends_at || "No end date"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
        )}
    </Container>
  );
};

export default ReferralUsersPage;

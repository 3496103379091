import { useState, useContext } from "react";
import { Formik, Form } from "formik";
import Input from "../../components/Input/";
import PrimaryButton from "../../components/Button/index";
import Logo from "../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";
import { loginUser, requestPasswordReset, setTokenHeader } from "../../api/";
import { toast } from "react-toastify";
import { useLocalStorage } from "../../utils/useLocalStorage";
import Loading from "../../components/LoadingOld/index";
import Modal from "../../components/Modal/index";
import { LangContext } from "../../context/LangContext";
import { Box, Divider, Heading, Image } from "@chakra-ui/react";
import { useCurrentUser } from "../../context/UserContext";

export default function LoginPage() {
  const { setCurrentUser } = useCurrentUser();

  const [loading, setLoading] = useState();
  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage("token", "");
  const { dictionary } = useContext(LangContext);

  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const history = useHistory();

  return (
    <Box className="login-container">
      <Image src={Logo} className="logo" alt="Logo" />
      <Loading isLoading={loading} />
      <Modal
        isOpen={resetPasswordModal}
        onClose={() => setResetPasswordModal(false)}
        className="reset-password-modal"
      >
        <Heading as="h4">{dictionary["password.reset"]}</Heading>
        <Divider
          width="2px"
          height="20px"
          backgroundColor="#AFCB2D"
          my="0"
          mx="20px"
        />
        <Box>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={async (data, { setErrors }) => {
              const response = await requestPasswordReset(data);
              if (response.status === 200) {
                setResetPasswordModal(false);
                toast.info(dictionary["check.inbox"]);
              } else {
                toast.error(response.data.errors.email[0]);
              }
            }}
          >
            {({ values, errors, handleChange }) => (
              <Form>
                <label style={{ justifyContent: "flex-start" }} htmlFor="email">
                  {dictionary["enter.email.address"]}
                </label>
                <Input
                  name="email"
                  type="email"
                  id="email"
                  error={errors.email}
                  value={values.email}
                  onChange={handleChange}
                  required
                />
                <PrimaryButton
                  label={dictionary.submit}
                  className="reset-password-submit"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Box className="login-form-container">
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async (data) => {
            setLoading(true);
            const response = await loginUser(data);
            if (response.status === 200) {
              setUser(response.data.access_token);
              setCurrentUser(response.data.user);
              setTokenHeader(response.data.access_token);
              history.push("/");
            } else {
              let error;
              if (
                response.data.errors &&
                response.data.errors.email_or_password
              ) {
                error = response.data.errors.email_or_password[0];
              } else if (response.data.errors) {
                error = response.data.errors[0];
              }
              toast.error(error);
            }
            setLoading(false);
          }}
        >
          {({ errors, values, touched, handleChange }) => (
            <Form>
              <Box className="login-form">
                <Input
                  name="email"
                  type="email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                  onChange={handleChange}
                  labelPosition="right"
                  label={dictionary.email}
                  required
                />
                <Input
                  name="password"
                  type="password"
                  value={values.password}
                  error={errors.password}
                  touched={touched.password}
                  onChange={handleChange}
                  labelPosition="right"
                  label={dictionary.password}
                  required
                />
              </Box>
              <Box className="login-buttons">
                <PrimaryButton
                  className="login-button"
                  type="submit"
                  label={dictionary.login}
                />
                <PrimaryButton
                  className="password-resest-button"
                  label={dictionary["password.reset"]}
                  onClick={() => setResetPasswordModal(true)}
                />
                <PrimaryButton
                  className="registration-button"
                  label={dictionary.registration}
                  onClick={() => history.push("/registration")}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

const axios = require("axios");
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(async (config) => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}` || "",
    "Accept-Language": localStorage.getItem("lang") || "en",
  };
  return config;
});

axiosApiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;

import { useState, useContext } from "react";
import Container from "../../components/Container/index";
import { Formik, Form } from "formik";
import Input from "../../components/Input";
import PrimaryButton from "../../components/Button/index";
import { resetPassword } from "../../api/index";
import { useHistory } from "react-router-dom";
import Loading from "../../components/LoadingOld/";
import { toast } from "react-toastify";
import { LangContext } from "../../context/LangContext";

const queryString = require("query-string");

export default function ResetPasswordPage() {
  const { token } = queryString.parse(window.location.search);
  const { dictionary } = useContext(LangContext);
  const history = useHistory();
  const [loading, setLoading] = useState();

  return (
    <Container size="lg" className="password-reset">
      <h3 className="page-title">{dictionary["password.reset"]}</h3>
      <Loading isLoading={loading} />
      <Formik
        initialValues={{
          email: "",
          password: "",
          password_confirmation: "",
          token,
        }}
        validate={({ password, password_confirmation }) => {
          const newErrors = {};

          if (password !== password_confirmation) {
            newErrors.password_confirmation = "Passwords must be equal.";
          }

          return newErrors;
        }}
        onSubmit={async (data, { setErrors }) => {
          setLoading(true);
          const response = await resetPassword(data);
          if (response.status === 200) {
            history.push("/login");
            toast.success("Success");
          } else {
            setErrors(response.data.errors);
            toast.error(response.data.title);
          }
          setLoading(false);
        }}
      >
        {({ errors, values, touched, handleChange }) => (
          <Form>
            <Input
              name="email"
              type="email"
              label={dictionary.email}
              error={errors.email}
              value={values.email}
              touched={touched.email}
              onChange={handleChange}
            />
            <Input
              name="password"
              type="password"
              label={dictionary.password}
              error={errors.password}
              value={values.password}
              touched={touched.password}
              onChange={handleChange}
            />
            <Input
              name="password_confirmation"
              type="password"
              label={dictionary["password.repeat"]}
              error={errors.password_confirmation}
              value={values.password_confirmation}
              touched={touched.password_confirmation}
              onChange={handleChange}
            />
            <PrimaryButton
              label={dictionary.submit}
              type="submit"
              className="reset-password-button"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

import { useState } from "react";
import "./dropdown.css";
import Select from "react-select";
import { Box } from "@chakra-ui/react";
import useWindowDimensions from "../../utils/useWindowDimensions";

const Dropdown = ({
  label,
  name,
  labelPosition = "left",
  value,
  error,
  options,
  setFieldValue,
  registration,
  labelClassName,
}) => {
  const { width } = useWindowDimensions();
  const [labelClass, setLabelClass] = useState();

  const handleFocus = () => {
    if (!labelClass) {
      setLabelClass("focused");
    } else {
      setLabelClass("");
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "29px",
      height: "29px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "29px",
    }),
  };

  return (
    <Box
      className="dropdown"
      display={width <= 768 ? "flex" : ""}
      flexDirection={width <= 768 ? "column" : "row"}
    >
      {label && labelPosition === "left" && (
        <label
          style={{ fontFamily: "Segoe UI Semilight" }}
          className={
            labelClass ? labelClassName + " " + labelClass : labelClassName
          }
        >
          {label}
        </label>
      )}
      <Select
        options={options}
        id={name}
        value={{ value, label: value }}
        onChange={(option) => {
          setFieldValue(name, option.label);
          registration && setFieldValue("phone_number", option.dialCode);
        }}
        onFocus={handleFocus}
        onBlur={handleFocus}
        classNamePrefix="react-select"
        styles={customStyles}
      />
      {label && labelPosition === "right" && (
        <label className={labelClass ? labelClass : ""}>{label}</label>
      )}
      {!label && <></>}
      {error && (
        <>
          <h4 className="error">{error}</h4>
        </>
      )}
    </Box>
  );
};

export default Dropdown;

import { useState, useContext } from "react";
import Container from "../../components/Container/";
import { useQuery } from "react-query";
import { getNotifications } from "../../api";
import Pagination from "../../components/Pagination";
import PrimaryButton from "../../components/Button";
import { Table, Thead, Tr, Th, Td } from "@chakra-ui/react";
import { LangContext } from "../../context/LangContext";
import Loading from "../../components/Loading";

const Notifications = ({ setExpandedNotification, onOpen }) => {
  const { dictionary, userLanguage } = useContext(LangContext);

  const [page, setPage] = useState(1);

  const { isLoading, data, isError } = useQuery(
    ["notifications", userLanguage],
    () => getNotifications(false, false),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (isLoading) return <Loading />;
  if (isError) return "Error...";

  const {
    data: users,
    next_page_url: nextPage,
    prev_page_url: prevPage,
  } = data;

  return (
    <Container size="sm">
      <div className="logs">
        <Table variant="simple" className="table">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Title</Th>
              <Th>Type</Th>
              <Th>From</Th>
              <Th>Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <tbody>
            {users && users.length > 0 ? (
              users.map((item, index) => (
                <Tr
                  key={index}
                  color={item.seen ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,1)"}
                >
                  <Td data-label="#">{data.from + index}</Td>
                  <Td data-label="Title">{item.title}</Td>
                  <Td data-label="Type">{item.type}</Td>
                  <Td data-label="From">{item.from}</Td>
                  <Td data-label="Date">{item.created_at}</Td>
                  <Td data-label="Action">
                    {
                      <PrimaryButton
                        onClick={() => {
                          setExpandedNotification(item.id);
                          onOpen();
                        }}
                        className="primary-button"
                        label={dictionary["details"]}
                      ></PrimaryButton>
                    }
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr style={{ textAlign: "center" }}>No results found</Tr>
            )}
          </tbody>
        </Table>

        {/* Pagination */}
        <Pagination
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          page={page}
          currentPage={data.current_page}
        ></Pagination>
      </div>
    </Container>
  );
};

export default Notifications;

import { useState, useContext } from "react";
import Container from "../../components/Container/";
import "./profile.css";
import PrimaryButton from "../../components/Button/index";
import { requestVerifyEmail, requestPasswordReset } from "../../api";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../../components/Modal/";
import { Form, Formik } from "formik";
import Input from "../../components/Input";
import { LangContext } from "../../context/LangContext";
import { useCurrentUser } from "../../context/UserContext";

export default function ProfilePage() {
  const { currentUser } = useCurrentUser();

  const [emailSent, setEmailSent] = useState(false);
  const { dictionary } = useContext(LangContext);
  const history = useHistory();
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  return (
    <Container size="sm">
      <h3 className="page-title">Profile</h3>
      <Modal
        isOpen={changeEmailModal}
        onClose={() => setChangeEmailModal(false)}
        className="email-change"
        width="30%"
      >
        <h4>Change Email</h4>
        <hr className="divide" />
        <Formik
          initialValues={{ new_email: "" }}
          onSubmit={async ({ new_email }, { setErrors }) => {
            const response = await requestVerifyEmail(new_email);
            if (response.status === 200) {
              setChangeEmailModal(false);
              toast.info(dictionary["check.inbox"]);
            } else {
              if (response.data.title) {
                toast.error(response.data.title);
              } else if (response.data.errors !== null) {
                setErrors(response.data.errors);
              }
            }
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <Input
                name="new_email"
                label="New Email"
                values={values.new_email}
                type="email"
                error={errors.new_email}
                touched={touched.new_email}
                onChange={handleChange}
                required
              />
              <div style={{ float: "right" }}>
                <PrimaryButton label="Submit" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <div className="profile-box">
        {currentUser && (
          <>
            <UserField label={dictionary.name} value={currentUser.name} />
            <UserField label={dictionary.surname} value={currentUser.surname} />
            <UserField
              label={dictionary.email}
              value={currentUser.email}
              addon={
                <div className="email-buttons">
                  {currentUser.email_verified_at ? (
                    <p>Email verified</p>
                  ) : (
                    <PrimaryButton
                      label={
                        emailSent
                          ? dictionary["email.sent"]
                          : dictionary["verify.email"]
                      }
                      onClick={async () => {
                        const response = await requestVerifyEmail(null);
                        if (response.status === 200) {
                          setEmailSent(true);
                        }
                      }}
                    />
                  )}
                  <PrimaryButton
                    label={dictionary["change.email"]}
                    onClick={() => setChangeEmailModal(true)}
                  />
                </div>
              }
            />
            <UserField
              label={dictionary["phone.number"]}
              value={currentUser.phone_number}
            />
            <br />
            <h5>Address</h5>
            <UserField label={dictionary.country} value={currentUser.country} />
            <UserField label={dictionary.city} value={currentUser.city} />
            <UserField label={dictionary.street} value={currentUser.street} />
            <UserField
              label={dictionary["postal.code"]}
              value={currentUser.postal_code}
            />
            <br />
            <div className="profile-buttons">
              <PrimaryButton
                label={dictionary["logs"]}
                onClick={() => history.push("/logs")}
                className="profile-button"
              />
              <PrimaryButton
                label={dictionary["edit.profile"]}
                onClick={() => history.push("/profile/edit")}
                className="profile-button"
              />
              <PrimaryButton
                label={dictionary["password.change"]}
                onClick={async () => {
                  const response = await requestPasswordReset({
                    email: currentUser.email,
                  });
                  if (response.status === 200) {
                    toast.success(dictionary["email.sent"]);
                  } else {
                    if (response.data.errors.email) {
                      toast.error(response.data.errors.email[0]);
                    }
                  }
                }}
                className="profile-button"
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

const UserField = ({ label, value, addon }) => {
  return (
    <div className="user-field">
      <p className="label">{label}:</p>
      <p className="value">{value}</p>
      {addon && addon}
    </div>
  );
};

import { useContext } from "react";
import Logo from "../../assets/images/logo-white.svg";
import "./sidebar.css";
import { Transition } from "react-transition-group";
import { Box, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { getMenuLinks } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LangContext } from "../../context/LangContext";
import { ExpandedContext } from "../../context/ExpandedContext";
import { useQuery } from "react-query";
import { Button } from "@chakra-ui/react";
import useWindowDimensions from "../../utils/useWindowDimensions";

const Sidebar = () => {
  const { userLanguage } = useContext(LangContext);
  const { expanded, expandedChange } = useContext(ExpandedContext);
  const location = useLocation();

  const { height, width } = useWindowDimensions();
  const {
    isLoading,
    error,
    data: menu,
  } = useQuery(["menuItems", userLanguage], getMenuLinks, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading)
    return (
      <Box backgroundColor="#1A1919" color="#FFF">
        <Box display="flex" flexDirection="column">
          {expanded ? (
            <Image
              src={Logo}
              maxWidth="0"
              alt="logo"
              height={width <= 768 ? "auto" : "7em"}
              py="1em"
            />
          ) : (
            <Image
              src={Logo}
              alt="logo"
              height={width <= 768 ? "auto" : "7em"}
              py="1em"
            />
          )}

          <NavLink activeClassName="active" to="/">
            <Button
              _focus={{ boxShadow: "none" }}
              borderRadius={0}
              backgroundColor="#1A1919"
              _hover={{ backgroundColor: "#4D4D4D" }}
              display="flex"
              justifyContent="flex-start"
              width="100%"
            >
              <FontAwesomeIcon icon="fas fa-sync fa-spin"></FontAwesomeIcon>
              {!expanded ? <Text ml="1em">Loading</Text> : null}
            </Button>
          </NavLink>
        </Box>
      </Box>
    );

  if (error) return "An error has occurred: " + error.message;
  const duration = 300;

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1, maxWidth: "100vw" },
    entered: { opacity: 1, maxWidth: "100vw" },
    exiting: { opacity: 0, maxWidth: "0px" },
    exited: { opacity: 0, maxWidth: "0px" },
  };

  return (
    <Transition in={!expanded} timeout={duration}>
      {(state) => (
        <Box height={height} backgroundColor="#1A1919" color="#FFF">
          <Box display="flex" flexDirection="column">
            <Box style={{ ...defaultStyle, ...transitionStyles[state] }}>
              <Image
                src={Logo}
                alt="logo"
                py="1em"
                height={width <= 768 ? "auto" : "7em"}
              />
            </Box>
            {width <= 768 && !expanded ? (
              <Button
                backgroundColor="transparent"
                position="absolute"
                right="0.5em"
                top="0.5em"
                style={{ ...defaultStyle, ...transitionStyles[state] }}
                onClick={() => {
                  expandedChange();
                }}
                _hover={{ backgroundColor: "transparent" }}
                _focus={{ backgroundColor: "transparent" }}
                _active={{ backgroundColor: "transparent" }}
              >
                <FontAwesomeIcon
                  color="#AECB2D"
                  size="2x"
                  icon="fas fa-window-close"
                />
              </Button>
            ) : null}
            {menu.map((item) => (
              <Box key={item.id}>
                <NavLink
                  onClick={() =>
                    width <= 768 && !expanded ? expandedChange() : null
                  }
                  key={item.id}
                  activeClassName="active"
                  to={item.url}
                >
                  <Button
                    _focus={{ boxShadow: "none" }}
                    borderRadius={0}
                    backgroundColor="#1A1919"
                    _hover={{ backgroundColor: "#4D4D4D" }}
                    display="flex"
                    justifyContent="flex-start"
                    width="100%"
                  >
                    <FontAwesomeIcon icon={item.icon}></FontAwesomeIcon>
                    <Box
                      style={{ ...defaultStyle, ...transitionStyles[state] }}
                    >
                      <Text ml="1em">{item.name}</Text>
                    </Box>
                  </Button>
                </NavLink>
                <Box style={{ ...defaultStyle, ...transitionStyles[state] }}>
                  {item.child &&
                    location.pathname.includes("payout") &&
                    !expanded &&
                    Object.values(item.child).map((child) => (
                      <NavLink
                        onClick={() =>
                          width <= 768 && !expanded ? expandedChange() : null
                        }
                        key={child.id}
                        to={child.url}
                        activeClassName="activeChild"
                      >
                        <Button
                          className="child"
                          _focus={{ boxShadow: "none" }}
                          borderRadius={0}
                          fontSize="0.5em"
                          backgroundColor="#1A1919"
                          _hover={{ backgroundColor: "#4D4D4D" }}
                          display="flex"
                          justifyContent="flex-start"
                          width="100%"
                        >
                          {child.name}
                        </Button>
                      </NavLink>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Transition>
  );
};

export default Sidebar;

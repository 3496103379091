import { useEffect, useState, useContext } from "react";
import Container from "../../components/Container/";
import { useQuery } from "react-query";
import { getReferralUserCharts, getReferralUserDetails } from "../../api";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { Box } from "@chakra-ui/layout";
import { PolarArea } from "react-chartjs-2";
import { LangContext } from "../../context/LangContext";
import Loading from "../../components/Loading";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const ReferralUserDetailsPage = () => {
  const { dictionary } = useContext(LangContext);

  const [page, setPage] = useState(1);
  let { id } = useParams();
  const { data, isLoading } = useQuery(
    ["referralUserDetailsPage", id],
    () => getReferralUserDetails(id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { data: chartData, isLoading: isLoading2 } = useQuery(
    ["referralUserChartData", id],
    () => getReferralUserCharts(id),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [userData, setUserData] = useState();
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  useEffect(() => {
    if (data) {
      setPage(data.current_page);
      setUserData(data.data);
      setNextPage(data.next_page_url);
      setPrevPage(data.prev_page_url);
    }
  }, [data]);

  if (isLoading || isLoading2) return <Loading />;

  const allowed = [
    "companies",
    "company_attached_users",
    "company_coupons",
    "events",
    "facebook_pages",
    "facebook_shared_data",
    "image_templates",
    "locations",
    "news",
    "push_notifications",
    "s3_memory",
  ];

  const filteredChartData = Object.keys(chartData.data)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = chartData.data[key];
      return obj;
    }, {});

  const data6 = {
    labels: Object.keys(filteredChartData),

    datasets: [
      {
        data: Object.values(filteredChartData),
      },
    ],
  };

  const options = {
    backgroundColor: [
      "rgb(255, 99, 132)",
      "rgb(75, 192, 192)",
      "rgb(255, 205, 86)",
      "rgb(201, 203, 207)",
      "rgb(60, 162, 240)",
      "rgb(244, 105, 155)",
      "rgb(90, 192, 145)",
      "rgb(130, 220, 95)",
      "rgb(150, 220, 195)",
      "rgb(15, 120, 220)",
      "rgb(20, 130, 215)",
    ],
  };
  return (
    <Container size="sm">
      <h1 className="page-title">{dictionary["company.info"]}</h1>

      <div className="logs">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{dictionary["name"]}</th>
              <th>{dictionary["email"]}</th>
              <th>{dictionary["phone"]}</th>
              <th>{dictionary["country"]}</th>
              <th>{dictionary["address"]}</th>
              <th>{dictionary["coupons"]}</th>
              <th>{dictionary["news"]}</th>
              <th>{dictionary["locations"]}</th>
              <th>{dictionary["events"]}</th>
            </tr>
          </thead>

          <tbody>
            {userData &&
              userData.length > 0 &&
              userData.map((log, index) => (
                <tr key={index}>
                  <td data-label="#">{data.from + index}</td>
                  <td data-label={dictionary["name"]}>{log.name}</td>
                  <td data-label={dictionary["email"]}>{log.email}</td>
                  <td data-label={dictionary["phone"]}>{log.phone}</td>
                  <td data-label={dictionary["country"]}>{log.country}</td>
                  <td data-label={dictionary["address"]}>{log.address}</td>
                  <td data-label={dictionary["coupons"]}>
                    {log.coupons_count}
                  </td>
                  <td data-label={dictionary["news"]}>{log.news_count}</td>
                  <td data-label={dictionary["locations"]}>
                    {log.locations_count}
                  </td>
                  <td data-label={dictionary["events"]}>{log.events_count}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {userData && userData?.data?.length === 0 && (
          <Box display="flex" justifyContent="center" m="1em">
            There are no companies registered yet.
          </Box>
        )}

        {/* Pagination */}
        <Pagination
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          page={page}
          currentPage={data.current_page}
        ></Pagination>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={["0", "2em"]}
        >
          <Box width={["100%", "50%"]}>
            <PolarArea data={data6} options={options} />
          </Box>
        </Box>
      </div>
    </Container>
  );
};

export default ReferralUserDetailsPage;

import React from "react";
import { Redirect, Route, useHistory } from "react-router";
import { useLocalStorage } from "../../utils/useLocalStorage";

export default function ProtectedRoute({ children, access, ...args }) {
  const history = useHistory();

  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage("token", "");

  if (!user) {
    return (
      <Redirect
        to={{ pathname: "/login", state: { from: history.location } }}
      />
    );
  }

  return <Route {...args}>{children}</Route>;
}

import { Box, Text } from "@chakra-ui/layout";
import React, { useState } from "react";

import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Slidedown({ text, children }) {
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text cursor="pointer" onClick={() => setOpen(!open)} size="xl" m="1em">
          {text}
        </Text>
        {open ? (
          <FontAwesomeIcon fontSize={width <= 768 ? 16 : 22} icon="angle-up" />
        ) : (
          <FontAwesomeIcon
            fontSize={width <= 768 ? 16 : 22}
            icon="angle-down"
          />
        )}
      </Box>
      <SlideDown className={"my-dropdown-slidedown"}>
        {open ? children : null}
      </SlideDown>
    </Box>
  );
}

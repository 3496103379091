import React, { useState, createContext } from "react";

export const ExpandedContext = createContext({
  expanded: false,
});

export function ExpandedProvider({ children }) {
  const [expanded, setExpanded] = useState(true);

  const provider = {
    expanded,
    expandedChange: () => {
      setExpanded(!expanded);
    },
  };

  return (
    <ExpandedContext.Provider value={provider}>
      {children}
    </ExpandedContext.Provider>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  registration: "Registration",
  email: "Email",
  password: "Password",
  login: "Login",
  logs: "Logs",
  "password.reset": "Password Reset",
  "password.repeat": "Repeat Password",
  "password.change": "Change Password",
  name: "Name",
  surname: "Surname",
  country: "Country",
  city: "City",
  street: "Street",
  "postal.code": "Postal Code",
  "vat.number": "VAT Number",
  "phone.number": "Phone Number",
  "enter.email.address": "Enter your email address",
  "edit.profile": "Edit Profile",
  "verify.email": "Verify Email",
  "email.sent": "Email sent.",
  "change.email": "Change Email",
  submit: "Submit",
  link: "Your unique invitation link:",
  count: "Successfully invited users:",
  copy: "Copy",
  copied: "Copied",
  "enter.password.to.verify.email": "Enter your password to verify email",
  "email.verified": "Email verified successfully",
  "check.inbox": "Please, check your inbox",
  profile: "Profile",
  logout: "Log Out",
  "see.more": "See More",
  "no.notifications": "No Notifications Yet",
  "read.all": "Read All",
  all: "All",
  verified: "Verified",
  unverified: "Unverified",
  paid: "Paid",
  unpaid: "Unpaid",
  verification: "Verification",
  payment: "Payment",
  "user.page": "User Page",
  "open.details": "Open Details",
  "no.subscription": "No Subscription",
  true: "True",
  false: "False",
  "no.results": "No results found",
  search: "Search",
  "search.by.email": "Search by email",

  from: "From",
  IP: "IP Address",
  "no.detailed.info":
    " There isn't detailed information for this notification.",
  close: "Close",
  time: "Time",
  "paid.with": "Paid with",
  "fixed.credit": "Fixed Credit",
  cost: "Cost",
  "plan.id": "Plan Id",
  details: "Details",
  "no.credit.history": "No credit history",
  action: "Action",
  credits: "Credits",
  "minimum.payout": "Minimum Payout",
  "request.email": "You will receive payout request on email",
  "payout.progress": "Payout Progress",
  transactions: "Transactions",
  "verify.payout.email": "Verify payout email",
  title: "Title",
  date: "Date",
  previous: "Previous",
  next: "Next",
  description: "Description",
  "updated.at": "Updated at",
  phone: "Phone",
  address: "Address",
  coupons: "Coupons",
  news: "News",
  locations: "Locations",
  events: "Events",
  browser: "Browser",
  unknown: "Unknown",
  "no.companies.registered": "There are no companies registered yet.",
  "no.ip.address": "No ip address",
  "company.info": "Company info",
  "no.access": "Please verify your email to access website functionality",
  "resend.email": "Resend Email",
  "edit.email": "Edit email",
  "add.to.home": "Add to home screen",
  "link.created": "Link created:",
};

import React, { useContext } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useQuery } from "react-query";
import { getNotification, notificationUpdate } from "../../api";
import { LangContext } from "../../context/LangContext";
import Loading from "../../components/Loading";

export default function FullModal({ isOpen, expandedNotification, onClose }) {
  const { dictionary } = useContext(LangContext);

  const { data: notification, isLoading: isLoading2 } = useQuery(
    ["notification" + expandedNotification, expandedNotification],
    () => {
      if (!expandedNotification) return null;
      return getNotification(expandedNotification);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  if (!isLoading2) {
    if (notification && !notification?.data?.errors) {
      if (!notification.seen) {
        notificationUpdate(expandedNotification, true);
      }
    }
  }
  if (isLoading2) return <Loading />;
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{expandedNotification}</ModalHeader>
        <ModalCloseButton />
        {notification !== null ? (
          notification.description && notification.from === "stripe_webhook" ? (
            <ModalBody>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>#</Th>

                    <Th>1</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{dictionary.cost}</Td>
                    <Td>{notification.description.cost}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["fixed.credit"]}</Td>
                    <Td>{notification.description.fixed_credit}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["paid.with"]}</Td>
                    <Td>{notification.description.paid_with}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["plan.id"]}</Td>
                    <Td>{notification.description.plan_id}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["date"]}</Td>
                    <Td>{notification.created_at}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </ModalBody>
          ) : notification.description ? (
            <ModalBody>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>#</Th>

                    <Th>1</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{dictionary["title"]}</Td>
                    <Td>{notification.description.name}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["email"]}</Td>
                    <Td>{notification.description.email}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["from"]}</Td>
                    <Td>{notification.from}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["date"]}</Td>
                    <Td>{notification.created_at}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </ModalBody>
          ) : (
            <ModalBody>{dictionary["no.detailed.info"]}</ModalBody>
          )
        ) : (
          <ModalBody>{dictionary["no.detailed.info"]}</ModalBody>
        )}
        <ModalFooter>
          <Button onClick={onClose}>{dictionary["close"]}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

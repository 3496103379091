import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import LoginPage from "../pages/login";
import RegistrationPage from "../pages/registration";
import ResetPasswordPage from "../pages/reset-password";
import VerificationEmailPage from "../pages/verification-email";
import { Box } from "@chakra-ui/layout";
import LanguageSelector from "../components/LanguageSelector";

export default function Authentication() {
  return (
    <Box>
      <LanguageSelector absolute={true} />
      <Switch>
        <Route path="/password/reset/" component={ResetPasswordPage} />
        <Route path="/registration" component={RegistrationPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/email/verify" component={VerificationEmailPage} />
        <Redirect to="/login" />
      </Switch>
    </Box>
  );
}

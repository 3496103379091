import { Button } from "@chakra-ui/react";

export default function PrimaryButton({
  label,
  type = "button",
  onClick,
  className,
  ...rest
}) {
  return (
    <Button
      border="0px"
      mx="5px"
      my="10px"
      px="30px"
      py="4px"
      textTransform="uppercase"
      color="#FFF"
      letterSpacing="0.7px"
      fontFamily="Segoe UI Semibold"
      backgroundColor="#919191"
      className={`${className && className}`}
      type={type}
      onClick={onClick && onClick}
      {...rest}
    >
      {label}
    </Button>
  );
}

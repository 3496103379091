import React, { useContext } from "react";

import { languageOptions } from "../../lang";
import { LangContext } from "../../context/LangContext";
import Select from "react-select";
import { Box, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import en from "../../assets/images/en.svg";
import de from "../../assets/images/de.svg";

export default function LanguageSelector({ absolute }) {
  const { userLanguage, userLanguageChange } = useContext(LangContext);

  // set selected language by calling context method
  const handleLanguageChange = (e) => {
    userLanguageChange(e.value);
  };
  const options = Object.entries(languageOptions).map(([id, name]) => ({
    value: id,
    label: (
      <Box display="flex">
        <Image width="30px" src={id === "en" ? en : de} />
        <Text fontSize="sm" ml="0.3em">
          {name}
        </Text>
      </Box>
    ),
  }));
  return (
    <Box
      width="140px"
      mr="0.2em"
      position={absolute ? "absolute" : "relative"}
      zIndex="10"
      right={absolute ? "1" : "0"}
      top={absolute ? "1" : "0"}
    >
      <Select
        defaultValue={
          userLanguage === "en"
            ? {
                value: "en",
                label: (
                  <Box display="flex">
                    <Image width="30px" src={en} />
                    <Text fontSize="sm" ml="0.3em">
                      English
                    </Text>
                  </Box>
                ),
              }
            : {
                value: "de",
                label: (
                  <Box display="flex">
                    <Image width="30px" src={de} />
                    <Text fontSize="sm" ml="0.3em">
                      German
                    </Text>
                  </Box>
                ),
              }
        }
        menuPlacement="auto"
        menuPosition="fixed"
        placeholder="Stutu"
        options={options}
        onChange={handleLanguageChange}
      />
    </Box>
  );
}

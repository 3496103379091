import { useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import ProfilePage from "../pages/profile";
import RefferalLinksPage from "../pages/referral-links/";
import ProfileEditPage from "../pages/profile-edit/";
import LogoutPage from "../pages/logout/";
import HomePage from "../pages/home";
import PayoutTransactionPage from "../pages/payout-transactions";
import PayoutCreditsPage from "../pages/payout-credits";
import PayoutEmailVerification from "../pages/payout-email-verification";
import Logs from "../pages/logs";
import ProtectedRoute from "../components/ProtectedRoute";
import Notifications from "../pages/notifications";
import ReferralUserDetailsPage from "../pages/referral-users-details";
import ReferralUsersPage from "../pages/referral-users";
import { Box, Text } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import FullModal from "../components/FullModal";
import useWindowDimensions from "../utils/useWindowDimensions";
import { useCurrentUser } from "../context/UserContext";
import Loading from "../components/Loading";
import EmailVerification from "./EmailVerification";
import { getCurrentUser } from "../api";
import { useQuery } from "react-query";
import { Offline } from "react-detect-offline";

export default function Authenticated() {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { width } = useWindowDimensions();
  const [expandedNotification, setExpandedNotification] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useQuery("currentUser", getCurrentUser, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading) return <Loading />;
  setCurrentUser(data.user);

  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="wrap"
      height={width <= 768 ? "-webkit-fill-available" : "100vh"}
    >
      <Sidebar />

      <Box
        flex="1"
        height={width <= 768 ? "-webkit-fill-available" : "100vh"}
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Header
          currentUser={currentUser}
          onOpen={onOpen}
          setExpandedNotification={setExpandedNotification}
        />
        <Box>
          <Offline>
            <Text backgroundColor="red" textAlign="center" color="white">
              No internet connection
            </Text>
          </Offline>
        </Box>

        <Box overflowX="hidden" overflowY="scroll" flex="1">
          {!currentUser ? (
            <Loading></Loading>
          ) : currentUser.email_verified_at === null ? (
            <EmailVerification />
          ) : (
            <Switch>
              <ProtectedRoute path="/dashboard">
                <HomePage />
              </ProtectedRoute>
              <ProtectedRoute path="/logs">
                <Logs />
              </ProtectedRoute>
              <ProtectedRoute path="/profile/edit">
                <ProfileEditPage />
              </ProtectedRoute>
              <ProtectedRoute path="/payoutEmail/verify">
                <PayoutEmailVerification />
              </ProtectedRoute>
              <ProtectedRoute path="/payoutTransaction">
                <PayoutTransactionPage />
              </ProtectedRoute>
              <ProtectedRoute path="/logout" component={LogoutPage} />

              <ProtectedRoute path="/payoutCredits">
                <PayoutCreditsPage />
              </ProtectedRoute>
              <ProtectedRoute path="/notifications">
                {(props) => (
                  <Notifications
                    setExpandedNotification={setExpandedNotification}
                    onOpen={onOpen}
                    {...props}
                  />
                )}
              </ProtectedRoute>
              <ProtectedRoute path="/profile">
                <ProfilePage />
              </ProtectedRoute>
              <ProtectedRoute path="/referral/links">
                <RefferalLinksPage />
              </ProtectedRoute>
              <ProtectedRoute path="/referral/users/:id">
                <ReferralUserDetailsPage />
              </ProtectedRoute>
              <ProtectedRoute path="/referral/users">
                <ReferralUsersPage />
              </ProtectedRoute>
              <ProtectedRoute path="/logout" component={LogoutPage} />

              <Redirect from="/payout" to="/payoutCredits" />

              <Redirect to="/dashboard" />
            </Switch>
          )}
        </Box>
      </Box>

      <FullModal
        onOpen={onOpen}
        isOpen={isOpen}
        expandedNotification={expandedNotification}
        onClose={onClose}
      ></FullModal>
    </Box>
  );
}

import { useContext, useEffect, useState } from "react";
import Container from "../../components/Container";
import { LangContext } from "../../context/LangContext";
import { verifyPayoutEmail } from "../../api";
import Loading from "../../components/Loading";

const queryString = require("query-string");

const PayoutEmailVerification = () => {
  const { dictionary } = useContext(LangContext);
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);

  const { token } = queryString.parse(window.location.search);
  const verifyEmail = async () =>
    await verifyPayoutEmail(token).then((response) => {
      if (response.status === 200) {
        setSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  useEffect(() => {
    if (token) verifyEmail();
    // eslint-disable-next-line
  }, [token]);
  return (
    <Container size="lg">
      {loading ? (
        <Loading />
      ) : (
        <div className="center">
          {success ? (
            <h3>{dictionary["success"]}</h3>
          ) : (
            <h3>{"Error, Please try again later"}</h3>
          )}
        </div>
      )}
    </Container>
  );
};

export default PayoutEmailVerification;

import { IoMdClose } from "react-icons/io";
import { Box, Button } from "@chakra-ui/react";

export default function Modal({
  children,
  isOpen,
  onClose,
  className,
  width = "max-content",
}) {
  if (!isOpen) return <></>;
  return (
    <>
      <Box
        width="100%"
        height="100vh"
        position="fixed"
        top="0"
        left="0"
        backgroundColor="rgba(1,0,0,0.5)"
        zIndex={5}
        onClick={onClose}
      ></Box>
      <Box
        zIndex="10"
        width="max-content"
        minWidth="250px"
        height="max-content"
        min-height="100px"
        backgroundColor="#FFFFFF"
        boxShadow="0 0 10px rgba(0, 0, 0, 0.3)"
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        p="15px 20px"
        style={{ width }}
        className={`${className}`}
      >
        <Button
          m="0px"
          p="0px"
          top="10px"
          right="10px"
          backgorund="none"
          position="absolute"
          onClick={onClose}
        >
          <IoMdClose fontSize="22px" zIndex="5" />
        </Button>
        {children}
      </Box>
    </>
  );
}

import React from "react";
import { useCurrentUser } from "../context/UserContext";
import { useLocalStorage } from "../utils/useLocalStorage";
import Authenticated from "./Authenticated";
import Authentication from "./Authentication";

export default function Stacks() {
  const [user] = useLocalStorage("token", "");
  const { currentUser } = useCurrentUser();
  return currentUser || user ? <Authenticated /> : <Authentication />;
}

import Container from "../../components/Container";
import { Line } from "react-chartjs-2";
import "./home.css";
import { getChartData } from "../../api";
import { useQuery } from "react-query";
import Loading from "../../components/Loading";
export default function Home() {
  let modifiedData = [];

  const { data: chartData, isLoading } = useQuery("chartData", getChartData, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  if (isLoading) return <Loading />;

  const keys = Object.keys(chartData);
  const colors = ["#50799F", "#B0C752", "#1588E9"];
  const bgColors = ["#1D619F", "#9DC73C", "#1538E9"];
  for (let i = 0; i < keys.length; i++) {
    const itemData = {
      label: keys[i],
      data: chartData[keys[i]],
      parsing: {
        yAxisKey: "total",
        xAxisKey: "month",
      },
      fill: false,
      backgroundColor: colors[i],
      borderColor: bgColors[i],
    };
    modifiedData.push(itemData);
  }
  var d = new Date();

  const data = {
    labels: d.getMonth > "5" ? [7, 8, 9, 10, 11, 12] : [1, 2, 3, 4, 5, 6],
    datasets: modifiedData,
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 10,
      },
    },
  };
  return (
    <Container size="sm">
      <div className="bigChart">
        <Line data={data} options={options} />
      </div>
    </Container>
  );
}

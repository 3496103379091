import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import { LanguageProvider } from "./context/LangContext";
import { ChakraProvider } from "@chakra-ui/react";
import { CurrentUserProvider } from "./context/UserContext";
import { ExpandedProvider } from "./context/ExpandedContext";
import Stacks from "./stacks";
import IOSInstallPrompt from "./components/IOSInstallPrompt";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

function App() {
  let count = 0;
  const clickListener = (event) => {
    count++;
    if (count % 5 === 0) queryClient.refetchQueries("notificationsCount");
  };
  useEffect(() => {
    window.addEventListener("click", clickListener);
    return () => window.removeEventListener("click", clickListener);

    // eslint-disable-next-line
  }, []);
  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <CurrentUserProvider>
          <ExpandedProvider>
            <LanguageProvider>
              <ToastContainer position="top-left" />
              <Stacks />

              <IOSInstallPrompt />

              <div className="footer">
                <p className="footer-date">{new Date().toLocaleDateString()}</p>
                <p className="footer-version">
                  {process.env.REACT_APP_VERSION}
                </p>
              </div>
            </LanguageProvider>
          </ExpandedProvider>
        </CurrentUserProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;

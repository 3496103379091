import { Formik, Form } from "formik";
import { useState, useEffect, useContext } from "react";
import Container from "../../components/Container/index";
import Input from "../../components/Input/index";
import PrimaryButton from "../../components/Button/index";
import { registerUser, getCountries } from "../../api/";
import { useHistory } from "react-router-dom";
import Loading from "../../components/LoadingOld";
import { toast } from "react-toastify";
import Dropdown from "../../components/Dropdown";
import { useQuery } from "react-query";
import { LangContext } from "../../context/LangContext";

export default function RegistrationPage() {
  const [loading, setLoading] = useState();
  const history = useHistory();
  const { dictionary } = useContext(LangContext);
  const { data: initialCountries, isLoading } = useQuery(
    "countries",
    () => getCountries(true),
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (initialCountries) {
      let processedData = [];

      Object.keys(initialCountries)
        .slice(0, Object.keys(initialCountries).length - 1)
        .forEach((key, i) => {
          processedData.push({
            value: key,
            label: Object.values(initialCountries)[i].country,
            dialCode: Object.values(initialCountries)[i].dialCode,
          });
        });

      setCountries(processedData);
    }
  }, [initialCountries]);

  const handleSubmit = async (data, { setErrors, setSubmitting }) => {
    setLoading(true);
    const response = await registerUser(data);
    if (response.status === 200) {
      history.push("/login");
      toast.success(
        "Now, you can verify your email by checking your inbox or you can use your login and password to login into the Dashboard."
      );
    } else {
      setSubmitting(false);
      if (response.data.errors !== null) {
        setErrors(response.data.errors);
      }
    }
    setLoading(false);
  };
  if (isLoading) return "Loading...";
  return (
    <Container size="lg" className="registration">
      <Loading isLoading={loading} />
      <h4 className="page-title">{dictionary.registration}</h4>
      <Formik
        initialValues={{
          email: "",
          password: "",
          confirmPassword: "",
          name: "",
          surname: "",
          country: initialCountries.suggested.country,
          city: "",
          street: "",
          vat_number: "",
          postal_code: "",
          phone_number: initialCountries.suggested.dialCode,
        }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validate={({ password, confirmPassword }) => {
          const newErrors = {};

          if (password !== confirmPassword) {
            newErrors.confirmPassword = "Passwords must be equal.";
          }

          return newErrors;
        }}
      >
        {({ values, touched, errors, handleChange, setFieldValue }) => (
          <Form>
            <Input
              labelClassName="left"
              name="email"
              value={values.email}
              onChange={handleChange}
              type="email"
              label={dictionary.email}
              error={errors.email}
              touched={touched.email}
              required
            />
            <Input
              labelClassName="left"
              name="password"
              value={values.password}
              onChange={handleChange}
              type="password"
              label={dictionary.password}
              error={errors.password}
              touched={touched.password}
              required
            />
            <Input
              labelClassName="left"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              type="password"
              label={dictionary["password.repeat"]}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
              required
            />
            <Input
              labelClassName="left"
              name="name"
              label={dictionary.name}
              error={errors.name}
              touched={touched.name}
              value={values.name}
              onChange={handleChange}
            />
            <Input
              labelClassName="left"
              name="surname"
              label={dictionary.surname}
              error={errors.surname}
              touched={touched.surname}
              value={values.surname}
              onChange={handleChange}
              required
            />
            {countries && (
              <Dropdown
                labelClassName="left"
                registration={true}
                options={countries}
                name="country"
                label="Country"
                setFieldValue={setFieldValue}
                value={values.country}
                error={errors.country}
              />
            )}
            <Input
              labelClassName="left"
              name="city"
              label={dictionary.city}
              error={errors.city}
              touched={touched.city}
              value={values.city}
              onChange={handleChange}
              required
            />
            <Input
              labelClassName="left"
              name="street"
              label={dictionary.street}
              error={errors.street}
              touched={touched.street}
              value={values.street}
              onChange={handleChange}
              required
            />
            <Input
              labelClassName="left"
              name="postal_code"
              label={dictionary["postal.code"]}
              error={errors.postal_code}
              touched={touched.postal_code}
              value={values.postal_code}
              onChange={handleChange}
              required
            />
            <Input
              labelClassName="left"
              name="vat_number"
              value={values.vat_number}
              onChange={handleChange}
              label={dictionary["vat.number"]}
              error={errors.vat_number}
              touched={touched.vat_number}
            />
            <Input
              labelClassName="left"
              name="phone_number"
              value={values.phone_number}
              onChange={handleChange}
              label={dictionary["phone.number"]}
              error={errors.phone_number}
              touched={touched.phone_number}
              required
            />
            <PrimaryButton
              size="xs"
              label={dictionary.registration}
              type="submit"
              className="register-button"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
}

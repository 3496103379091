// eslint-disable-next-line import/no-anonymous-default-export
export default {
  registration: "Registrieren",
  email: "E-Mail Addresse",
  password: "Passwort",
  login: "Anmelden",
  logs: "Protokolle",
  "password.reset": "Passwort Versgessen",
  "password.repeat": "Passwort wiederholen",
  "password.change": "Passwortänderung",
  name: "Name",
  surname: "Nachname",
  country: "Land",
  street: "Straße",
  "postal.code": "Postal Code",
  "vat.number": "VAT Number",
  "phone.number": "Telefon nummer",
  "enter.email.address": "Geben sie ihre E-Mailadresse ein",
  "edit.profile": "Profil bearbeiten",
  "verify.email": "E-Mail-Verifizierung",
  "email.sent": "E-Mail gesendet.",
  "change.email": "Ändern Sie die E-Mail",
  submit: "Einreichen",
  link: "Ihr einzigartiger Einladungslink:",
  count: "Erfolgreich eingeladene Benutzer:",
  copy: "Kopieren",
  copied: "Kopiert",
  "enter.password.to.verify.email":
    "Geben Sie Ihr Passwort ein, um die E-Mail zu bestätigen",
  "email.verified": "E-Mail erfolgreich verifiziert",
  "check.inbox": "Bitte überprüfen Sie Ihren Posteingang",
  profile: "Profil",
  logout: "Ausloggen",
  "see.more": "Mehr sehen",
  "no.notifications": "Noch keine Benachrichtigungen",
  "read.all": "Lese alles",
  all: "Alle",
  verified: "Verifiziert",
  unverified: "Nicht bestätigt",
  paid: "Bezahlt",
  unpaid: "Unbezahlt",
  verification: "Überprüfung",
  payment: "Zahlung",
  "user.page": "Benutzerseite",
  "open.details": "Details öffnen",
  "no.subscription": "Kein Abonnement",
  true: "Wahr",
  false: "Falsch",
  "no.results": "keine Ergebnisse gefunden",
  search: "Suche",
  "search.by.email": "Per E-Mail suchen",

  from: "Von",
  IP: "IP Addresse",
  city: "Stadt",
  "no.detailed.info":
    "Für diese Benachrichtigung liegen keine detaillierten Informationen vor.",
  close: "Nah dran",
  time: "Zeit",
  "paid.with": "Bezahlt mit",
  "fixed.credit": "Fester Kredit",
  cost: "Kosten",
  "plan.id": "Plan Id",
  details: "Einzelheiten",
  "no.credit.history": "Keine Kredithistorie",
  action: "Handlung",
  credits: "Credits",
  "minimum.payout": "Minimale Auszahlung",
  "request.email": "Sie erhalten eine Auszahlungsanforderung per E-Mail",
  "payout.progress": "Auszahlungsfortschritt",
  transactions: "Transaktionen",
  "verify.payout.email": "Auszahlungs-E-Mail überprüfen",
  title: "Titel",
  date: "Datum",
  previous: "Vorherige",
  next: "Nächste",
  description: "Beschreibung",
  "updated.at": "Aktualisiert am",
  phone: "Telefon",
  address: "die Anschrift",
  coupons: "Gutscheine",
  news: "Nachrichten",
  locations: "Standorte",
  events: "Veranstaltungen",
  browser: "Browser",
  unknown: "Unbekannt",
  "no.companies.registered": "Es sind noch keine Firmen registriert.",
  "no.ip.address": "Keine IP-Adresse",
  "company.info": "Firmeninfo",
  "no.access":
    "Bitte bestätigen Sie Ihre E-Mail-Adresse, um auf die Website-Funktionalität zugreifen zu können",
  "resend.email": "E-Mail zurücksenden",
  "edit.email": "E-Mail bearbeiten",
  "add.to.home": "Zum Startbildschirm hinzufügen",
  "link.created": "Link erstellt",
};

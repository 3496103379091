import { useContext } from "react";
import Container from "../../components/Container/";
import { Formik, Form } from "formik";
import { updateUser, getCountries } from "../../api/";
import Input from "../../components/Input";
import PrimaryButton from "../../components/Button/index";
import { useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Dropdown from "../../components/Dropdown";
import { LangContext } from "../../context/LangContext";
import { useCurrentUser } from "../../context/UserContext";
import Loading from "../../components/Loading";

const ProfileEditPage = () => {
  const queryClient = useQueryClient();
  let processedData = [];
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { dictionary } = useContext(LangContext);

  const {
    data: initialCountries,
    isLoading,
    isError,
  } = useQuery("countries", () => getCountries(false), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading) return <Loading />;
  if (isError) return "Error";

  Object.keys(initialCountries)
    .slice(0, Object.keys(initialCountries).length - 1)
    .forEach((key, i) => {
      processedData.push({
        value: key,
        label: Object.values(initialCountries)[i].country,
        dialCode: Object.values(initialCountries)[i].dialCode,
      });
    });

  const countries = processedData;

  if (!countries.length) return <Loading />;

  return (
    <Container size="sm">
      <h1 className="page-title">{dictionary["edit.profile"]}</h1>
      {currentUser && (
        <Formik
          initialValues={{
            name: currentUser.name,
            surname: currentUser.surname,
            email: currentUser.email,
            phone_number: currentUser.phone_number,
            country: currentUser.country,
            city: currentUser.city,
            street: currentUser.street,
            vat_number: currentUser.vat_number,
            postal_code: currentUser.postal_code,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (data, { setErrors, setSubmitting }) => {
            const response = await updateUser(data);
            if (response.status === 200) {
              queryClient.refetchQueries("currentUser");
              history.push("/profile");
            } else {
              setSubmitting(false);
              if (response.data.errors !== null) {
                setErrors(response.data.errors);
              }
            }
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Input
                label={dictionary.name}
                value={values.name}
                name="name"
                error={errors.name}
                onChange={handleChange}
                touched={touched.name}
              />
              <Input
                label={dictionary.surname}
                value={values.surname}
                name="surname"
                error={errors.surname}
                onChange={handleChange}
                touched={touched.surname}
              />
              <Input
                label={dictionary["phone.number"]}
                value={values.phone_number}
                name="phone_number"
                type="tel"
                error={errors.phone_number}
                onChange={handleChange}
                touched={touched.phone_number}
              />
              {countries && (
                <Dropdown
                  registration={true}
                  options={countries}
                  name="country"
                  label={dictionary.country}
                  value={values.country}
                  setFieldValue={setFieldValue}
                />
              )}
              <Input
                label={dictionary.street}
                value={values.street}
                name="street"
                error={errors.street}
                onChange={handleChange}
                touched={touched.street}
              />
              <Input
                label={dictionary["vat.number"]}
                value={values.vat_number}
                name="vat_number"
                error={errors.vat_number}
                onChange={handleChange}
                touched={touched.vat_number}
              />
              <Input
                label={dictionary["postal.code"]}
                value={values.postal_code}
                name="postal_code"
                error={errors.postal_code}
                onChange={handleChange}
                touched={touched.postal_code}
              />
              <div style={{ float: "right" }}>
                <PrimaryButton label={dictionary.submit} type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default ProfileEditPage;

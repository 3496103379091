import { useContext } from "react";

import { Box } from "@chakra-ui/react";
import PrimaryButton from "../Button";
import { LangContext } from "../../context/LangContext";
export default function Pagination({
  setPage,
  currentPage,
  nextPage,
  prevPage,
}) {
  const { dictionary } = useContext(LangContext);

  return (
    <Box display="flex" justifyContent="center" className="pagination">
      {prevPage && (
        <PrimaryButton
          className="primary-button"
          onClick={() => {
            setPage(currentPage - 1);
          }}
          label={dictionary["previous"]}
        ></PrimaryButton>
      )}
      {nextPage && (
        <PrimaryButton
          className="primary-button"
          onClick={() => {
            setPage(currentPage + 1);
          }}
          label={dictionary["next"]}
        ></PrimaryButton>
      )}
    </Box>
  );
}

import { useState, useContext } from "react";
import { getReferralLinks } from "../../api";
import "./referral-links.css";
import { useQuery } from "react-query";
import { LangContext } from "../../context/LangContext";
import Loading from "../../components/Loading";
import { Box, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Input, InputRightElement } from "@chakra-ui/input";
import { InputGroup } from "@chakra-ui/react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import PrimaryButton from "../../components/Button";

const RefferalLinksPage = () => {
  const { dictionary } = useContext(LangContext);
  const [show, setShow] = useState(false);
  const [copyImageText, setCopyImageText] = useState("COPY AS IMAGE");
  const [copyImageLinkText, setCopyImageLinkText] = useState("COPY AS LINK");
  const handleClick = (link) => {
    setShow(!show);
    navigator.clipboard.writeText(link);
  };
  const { width } = useWindowDimensions();
  const { data, isLoading } = useQuery("referral-links", getReferralLinks, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isLoading) return <Loading />;
  function handleScreenshot(link) {
    async function screenShotIOS() {
      const makeImagePromise = async () => {
        const response = await fetch(link, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            Origin: window.location.origin,
          },
        });
        return await response.blob();
      };

      navigator.clipboard
        .write([new window.ClipboardItem({ "image/png": makeImagePromise() })])
        .then(function () {
          console.log("copied");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    async function screenShot() {
      try {
        const response = await fetch(link, {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            Origin: window.location.origin,
          },
        });
        const blob = await response.blob();
        await navigator.clipboard.write([
          new window.ClipboardItem({ "image/png": blob }),
        ]);
      } catch (err) {
        console.error(err);
      }
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
      screenShotIOS();
    else screenShot();
  }

  const { data: links } = data;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      {links && links.length > 0 ? (
        links.map((link, index) => (
          <Box
            alignSelf="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            key={index}
            width={width <= 768 ? "90%" : "30%"}
          >
            <Text mb={2} mt={4} fontWeight="700">
              {dictionary.count}
            </Text>
            <Text fontWeight="300">{link.used}</Text>
            <Text mb={2} mt={4} fontWeight="700">
              {dictionary["link.created"]}
            </Text>
            <Text fontWeight="300">{link.created_at}</Text>
            <Text mb={2} mt={4} fontWeight="700">
              {dictionary.link}
            </Text>
            <InputGroup onClick={() => handleClick(link.link)}>
              <Input
                variant="filled"
                disabled
                fontWeight="300"
                value={link.link}
              ></Input>
              <InputRightElement width="4.5rem">
                <PrimaryButton
                  label={show ? "COPIED" : "COPY"}
                  h="1.75rem"
                  size="sm"
                ></PrimaryButton>
              </InputRightElement>
            </InputGroup>
            <Text mb={2} mt={4} fontWeight="700">
              QR Code
            </Text>

            <Image src={"https://static.yezzgo.de/images/qr/" + link.qr_code} />

            <Box
              mt={3}
              display="flex"
              flexDirection={width <= 768 ? "column" : "row"}
            >
              <PrimaryButton
                onClick={() => {
                  handleScreenshot(
                    "https://static.yezzgo.de/images/qr/" + link.qr_code
                  );
                  setCopyImageText("Copied");
                }}
                label={copyImageText}
              ></PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  navigator.clipboard.writeText(link.link);
                  setCopyImageLinkText("Copied");
                }}
                label={copyImageLinkText}
              ></PrimaryButton>
            </Box>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
};

export default RefferalLinksPage;

import Skeleton from "react-loading-skeleton";
import Container from "../Container";

export default function Loading() {
  return (
    <Container>
      <Skeleton
        count={10}
        width="100%"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      />
    </Container>
  );
}

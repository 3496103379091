import axiosApiInstance from "./interceptor";

const apiUrl =
  process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION;

export const setTokenHeader = (token) => {
  if (token) {
    axiosApiInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  } else {
    delete axiosApiInstance.defaults.headers.common["Authorization"];
  }
};

export const registerUser = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${apiUrl}/auth/register/distributor`,
      data
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axiosApiInstance.post(`${apiUrl}/auth/login`, data);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const logoutUser = async () => {
  try {
    const response = await axiosApiInstance.post(`${apiUrl}/auth/logout`);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const requestPasswordReset = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${apiUrl}/auth/recover`,
      data
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axiosApiInstance.post(`${apiUrl}/auth/reset`, data);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axiosApiInstance.get(`${apiUrl}/auth/me`);
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const updateUser = async (data) => {
  try {
    const response = await axiosApiInstance.put(`${apiUrl}/auth/me`, data);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getNotifications = async (count = false, seen) => {
  try {
    const response = await axiosApiInstance.get(
      seen
        ? `${apiUrl}/dashboard/inAppNotifications?count=${count}&seen=false`
        : `${apiUrl}/dashboard/inAppNotifications?count=${count}`
    );
    return response.data;
  } catch (ex) {
    return ex.response;
  }
};

export const notificationUpdate = async (id, seen) => {
  try {
    const response = await axiosApiInstance.put(
      `${apiUrl}/dashboard/inAppNotifications/${id}?seen=${seen}`,
      {}
    );
    return response.data;
  } catch (ex) {
    return ex.response;
  }
};

export const makeAllRead = async (seen, idArray) => {
  try {
    const response = await axiosApiInstance.post(
      `${apiUrl}/dashboard/inAppNotifications?seen=${seen}&ids=${idArray}`,
      {}
    );
    return response.data;
  } catch (ex) {
    return ex.response;
  }
};

export const getNotification = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/inAppNotifications/${id}`
    );
    return response.data;
  } catch (ex) {
    return ex.response;
  }
};

export const requestVerifyEmail = async (new_email) => {
  try {
    const response = await axiosApiInstance.put(
      `${apiUrl}/resend/emailVerification${
        new_email ? `?new_email=${new_email}` : ""
      }`,
      undefined
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const verifyEmail = async (data) => {
  try {
    const response = await axiosApiInstance.post(
      `${apiUrl}/email/verify?token=${data.token}`,
      data
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getMenuLinks = async () => {
  try {
    const response = await axiosApiInstance.get(`${apiUrl}/menu`);
    return response.data;
  } catch (ex) {
    return ex.response;
  }
};

export const getReferralLinks = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/referral/links`
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getLogs = async (page = 1) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/logs?page=` + page
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getChartData = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/distributor/analytics?referred_users=true&credits=true&payouts=true`
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getReferralUsers = async (
  page = 1,
  emailFilter = "",
  paid,
  verified
) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/referral/users?page=${page}&email=${emailFilter}&email_verified=${verified}&paid=${paid}`
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getReferralUserDetails = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/referral/users/` + id
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getReferralUserCharts = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/referral/appUsage/` + id
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getCountries = async (suggested = true) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/auth/countries?suggested=` + suggested
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getPayoutInfo = async () => {
  try {
    const response = await axiosApiInstance.get(`${apiUrl}/dashboard/payout`);
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getCreditHistory = async () => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/payoutCredits`
    );
    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};

export const getCredit = async (id) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/dashboard/payoutCredits/${id}`
    );

    return response.data;
  } catch (ex) {
    return ex.response.data;
  }
};
export const updatePayoutEmail = async (id, data) => {
  try {
    const response = await axiosApiInstance.put(
      `${apiUrl}/dashboard/payout/${id}`,
      data
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const verifyPayoutEmail = async (token) => {
  try {
    const response = await axiosApiInstance.get(
      `${apiUrl}/payoutEmail/verify?token=${token}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getNotifications, makeAllRead } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "react-query";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Box, Text } from "@chakra-ui/layout";
import { LangContext } from "../../context/LangContext";
import { Detector } from "react-detect-offline";

export default function Notifications({ setExpandedNotification, onOpen }) {
  const { dictionary, userLanguage } = useContext(LangContext);
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const { data: notificationsData, isLoading } = useQuery(
    ["notifications", userLanguage],
    () => getNotifications(false, false),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: notificationsCount, isLoading: countLoading } = useQuery(
    "notificationsCount",
    () => getNotifications(true, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    return setActive(false);
    // eslint-disable-next-line
  }, []);
  const [active, setActive] = useState(false);
  let idArray;

  if (!isLoading)
    if (notificationsData) {
      if (notificationsData.data.length) {
        idArray = notificationsData.data.map((a) => a.id);
      }
    }
  return (
    <Box
      position={width <= 768 ? "relative" : "relative"}
      className="notifications "
      data-dropdown
    >
      <Detector
        render={({ online }) => (
          <button
            onClick={() => {
              setActive(!active);
              if (!active && online)
                queryClient.refetchQueries("notifications");
            }}
            className="link-drop"
            data-dropdown-button
          >
            {!countLoading &&
            notificationsCount &&
            notificationsCount.amount ? (
              <Box
                position="absolute"
                right="0"
                top="0"
                transform="translate(50%,-40%)"
                backgroundColor="#DE3F4E"
                padding="2px 7px"
                borderRadius="10px"
                fontSize="xs"
              >
                {notificationsCount.amount}
              </Box>
            ) : null}
            <FontAwesomeIcon fontSize={width <= 768 ? 16 : 22} icon="bell" />
          </button>
        )}
      />
      {!isLoading && (
        <Box
          position="absolute"
          right={width <= 768 ? "-6em" : 0}
          top="calc(100% + .25rem)"
          backgroundColor="white"
          padding=".75rem"
          width={width <= 768 ? "60vw" : "40vw"}
          borderRadius=".25rem"
          boxShadow="0 2px 5px 0 rgba(0, 0, 0, .1)"
          opacity={active ? "1" : "0"}
          pointerEvents={active ? "auto" : "none"}
          transform={active ? "translateY(0)" : "translateY(-10px)"}
          transition="opacity 150ms ease-in-out, transform 150ms ease-in-out"
          className="dropdown-menu information-grid"
        >
          {notificationsData.data.length ? (
            <Box>
              {notificationsData.data.map((item) => (
                <Box key={item.id}>
                  <Box
                    pt={2}
                    cursor="pointer"
                    onClick={() => {
                      setExpandedNotification(item.id);
                      onOpen();
                    }}
                  >
                    <Box
                      color={item.seen ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,1)"}
                    >
                      <Text fontSize="lg">{item.title}</Text>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingBottom="0.5rem"
                      >
                        <Text
                          backgroundColor={
                            item.seen ? "rgba(239,78,66,0.4)" : "#EF4E42"
                          }
                          p="0.2em"
                          color="white"
                          fontSize="xs"
                        >
                          {item.type}
                        </Text>
                        <Text fontSize="xs">{item.created_at}</Text>
                      </Box>
                    </Box>
                    <hr></hr>
                  </Box>
                </Box>
              ))}
              <Box display="flex" justifyContent="space-between">
                <Text
                  cursor="pointer"
                  fontSize="sm"
                  onClick={() => {
                    try {
                      makeAllRead(true, idArray.toString()).then(() => {
                        queryClient.refetchQueries("notifications");
                        queryClient.refetchQueries("notificationsCount");
                      });
                    } catch {}
                  }}
                >
                  {dictionary["read.all"]}
                </Text>
                <Link to="/notifications">
                  <Text fontSize="sm">{dictionary["see.more"]}</Text>
                </Link>
              </Box>
            </Box>
          ) : (
            <Box>{dictionary["no.notifications"]}</Box>
          )}
        </Box>
      )}
    </Box>
  );
}

import { useState } from "react";
import "./input.css";
import { Input } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import useWindowDimensions from "../../utils/useWindowDimensions";

export default function CustomInput({
  name,
  type = "text",
  onChange,
  value,
  label,
  error,
  touched,
  labelPosition = "left",
  required = false,
  labelClassName,
}) {
  const [labelClass, setLabelClass] = useState();
  const { width } = useWindowDimensions();
  const handleFocus = () => {
    if (!labelClass) {
      setLabelClass("focused");
    } else {
      setLabelClass();
    }
  };

  return (
    <Box
      className="input"
      display="grid"
      gridTemplateColumns={width <= 768 ? "1fr" : "0.4fr 1fr"}
      mt="10px"
      mb="10px"
    >
      {label && labelPosition === "left" && (
        <label
          style={{ fontFamily: "Segoe UI Semilight", order: "1" }}
          htmlFor={name}
          className={labelClassName + " " + (labelClass && labelClass)}
        >
          {label}
        </label>
      )}
      <Input
        padding={0}
        paddingLeft={"6px"}
        borderColor="#9E9F9E"
        height="25px"
        fontFamily="Segoe UI Semilight"
        borderRadius="0px"
        name={name}
        type={type}
        id={name}
        onFocus={handleFocus}
        onBlur={handleFocus}
        onChange={onChange}
        value={value}
        order={width <= 768 ? "2" : "1"}
        required={required}
      />
      {label && labelPosition === "right" && (
        <label
          style={{ fontFamily: "Segoe UI Semilight", order: "1" }}
          htmlFor={name}
          className={labelClassName + " " + (labelClass && labelClass)}
        >
          {label}
        </label>
      )}
      {!label && <></>}
      {error && touched && (
        <>
          <div></div>
          <h4 className="error">{error}</h4>
        </>
      )}
    </Box>
  );
}

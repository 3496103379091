import { Box } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

export default function Loading({ isLoading }) {
  if (!isLoading) return <></>;
  return (
    <Box
      zIndex="10"
      width="100%"
      height="100vh"
      position="fixed"
      top="0"
      left="0"
      backgroundColor="rgba(0, 0, 0, 0.3)"
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <BeatLoader size={26} />
      </Box>
    </Box>
  );
}

import { useState, useContext } from "react";
import Container from "../../components/Container";
import { useQuery } from "react-query";
import { getPayoutInfo, getCreditHistory, getCredit } from "../../api";
import { Button, Box } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import ProgressBar from "@ramonak/react-progress-bar";
import PrimaryButton from "../../components/Button";
import { LangContext } from "../../context/LangContext";
import { useHistory } from "react-router-dom";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { Slidedown } from "../../components/Slidedown";
import Loading from "../../components/Loading";
import useWindowDimensions from "../../utils/useWindowDimensions";
import VerifyEmail from "../verify";

const PayoutCreditsPage = () => {
  const { width } = useWindowDimensions();
  const { dictionary, userLanguage } = useContext(LangContext);
  const history = useHistory();

  const { data: payoutInfo, isLoading } = useQuery(
    ["payout-info"],
    getPayoutInfo,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const { data: creditInfo, isLoading: isLoading2 } = useQuery(
    ["creditHistory", userLanguage],
    getCreditHistory,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [expanded, setExpanded] = useState();
  const { data: credit, isLoading: isLoading3 } = useQuery(
    ["credit" + expanded, expanded],
    () => {
      if (!expanded) return null;
      return getCredit(expanded);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  if (isLoading) return <Loading />;

  if (payoutInfo?.errors) return "Error";
  if (!payoutInfo.paypal_payout_email_verified_at)
    return (
      <VerifyEmail
        id={payoutInfo.id}
        email={payoutInfo.paypal_payout_email}
        payout={true}
      ></VerifyEmail>
    );
  if (creditInfo?.errors || credit?.errors) return "Error";
  if (isLoading2 || isLoading3) return <Loading />;

  return (
    <Container size="sm">
      <Box
        width="100%"
        display="grid"
        gridTemplateColumns={width <= 768 ? "1fr" : "1fr 1fr"}
      >
        <PrimaryButton
          label={dictionary["credits"]}
          onClick={() => history.push("/payout")}
          className="profile-button active"
        />
        <PrimaryButton
          label={dictionary["transactions"]}
          onClick={() => history.push("/payoutTransaction")}
          className="profile-button"
        />
      </Box>
      <h2>{dictionary["payout.progress"]}</h2>

      <div style={{ marginTop: 10 }}>
        <ProgressBar
          height="30px"
          bgColor="#AFCB2D"
          completed={(payoutInfo.credits / payoutInfo.payout_minimum) * 100}
        />
        <p>
          {dictionary["credits"]}: {payoutInfo.credits}
        </p>
        <p>
          {dictionary["minimum.payout"]}: {payoutInfo.payout_minimum}
        </p>
        <p> {dictionary["request.email"]}:</p>
        <h4>{payoutInfo.paypal_payout_email}</h4>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>{dictionary["cost"]}</th>
            <th>{dictionary["fixed.credit"]}</th>
            <th>{dictionary["paid.with"]}</th>
            <th>{dictionary["time"]}</th>
            <th>{dictionary["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {creditInfo && creditInfo.data.length > 0 ? (
            creditInfo.data.map((item, index) => (
              <tr key={index}>
                <td data-label="#">{creditInfo.from + index}</td>
                <td data-label="Cost">{item.cost}</td>
                <td data-label="Fixed Credit">{item.fixed_credit}</td>
                <td data-label="Paid With">{item.paid_with}</td>
                <td data-label="Time">{item.created_at}</td>
                <td data-label="Details">
                  <PrimaryButton
                    label={dictionary["details"]}
                    className="profile-button"
                    onClick={() => {
                      setExpanded(item.id);
                      onOpen();
                    }}
                  ></PrimaryButton>
                </td>
              </tr>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              {dictionary["no.credit.history"]}
            </div>
          )}
        </tbody>
      </table>
      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{expanded}</ModalHeader>
          <ModalCloseButton />
          {credit && credit.paid_by ? (
            <ModalBody>
              <Table variant="striped">
                <Tbody>
                  <Tr>
                    <Td>{dictionary["plan.id"]}</Td>
                    <Td>{credit.plan_id}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["cost"]}</Td>
                    <Td>{credit.cost}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["fixed.credit"]}</Td>
                    <Td>{credit.fixed_credit}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["paid.with"]}</Td>
                    <Td>{credit.paid_with}</Td>
                  </Tr>
                  <Tr>
                    <Td>{dictionary["name"]}</Td>
                    <Td>{credit.paid_by.name}</Td>
                  </Tr>

                  <Tr>
                    <Td>{dictionary["time"]}</Td>
                    <Td>{credit.created_at}</Td>
                  </Tr>
                </Tbody>
              </Table>

              <Slidedown text="User Information">
                <Table variant="striped">
                  <Tbody>
                    <Tr>
                      <Td>{dictionary["email"]}</Td>
                      <Td>{credit.paid_by.surname}</Td>
                    </Tr>
                    <Tr>
                      <Td>{dictionary["from"]}</Td>
                      <Td>{credit.paid_by.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>{dictionary["IP"]}</Td>
                      <Td>{credit.paid_by.ip}</Td>
                    </Tr>
                    <Tr>
                      <Td>{dictionary["vat.number"]}</Td>
                      <Td>{credit.paid_by.vat_number}</Td>
                    </Tr>
                    <Tr>
                      <Td>{dictionary["city"]}</Td>
                      <Td>{credit.paid_by.city}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Slidedown>
            </ModalBody>
          ) : (
            <ModalBody>{dictionary["no.detailed.info"]}</ModalBody>
          )}
          <ModalFooter>
            <Button onClick={onClose}> {dictionary["close"]}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default PayoutCreditsPage;

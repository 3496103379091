import { Box, Text } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/Button";
import { requestVerifyEmail, updatePayoutEmail } from "../../api";
import { LangContext } from "../../context/LangContext";
import { useDisclosure } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function VerifyEmail({ payout, email, id }) {
  const { dictionary } = useContext(LangContext);
  const [newEmail, setNewEmail] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (payout)
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box display="flex" flexDirection="column">
          <PrimaryButton
            onClick={onOpen}
            label={dictionary["edit.email"]}
          ></PrimaryButton>
          <Text>
            If you have not received notification please try to resend email or
            contact support
          </Text>
          <PrimaryButton
            onClick={async () => {
              const response = await updatePayoutEmail(id, {
                payout_email: email,
              });
              if (response.status === 200) {
                toast.success("Email Sent");
              } else {
                toast.error(response.error);
              }
            }}
            label={dictionary["resend.email"]}
          ></PrimaryButton>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enter new email</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="example@example.com"
              />
            </ModalBody>

            <ModalFooter>
              <PrimaryButton
                onClick={async () => {
                  const response = await updatePayoutEmail(id, {
                    payout_email: newEmail,
                  });
                  if (response.status === 200) {
                    onClose();
                    setNewEmail("");
                    toast.success("Email Sent");
                  } else {
                    toast.error(response.error);
                  }
                }}
                label="Save"
                mr={3}
              ></PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  onClose();
                  setNewEmail("");
                }}
                label="Cancel"
              ></PrimaryButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text>{dictionary["no.access"]}</Text>
      <PrimaryButton
        onClick={onOpen}
        label={dictionary["edit.email"]}
      ></PrimaryButton>
      <PrimaryButton
        onClick={async () => {
          const response = await requestVerifyEmail();
          if (response.status === 200) {
            toast.success("Email Sent");
          } else {
            toast.error(response.error);
          }
        }}
        label={dictionary["resend.email"]}
      ></PrimaryButton>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter new email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="example@example.com"
            />
          </ModalBody>

          <ModalFooter>
            <PrimaryButton
              onClick={async () => {
                const response = await requestVerifyEmail(newEmail);
                if (response.status === 200) {
                  onClose();
                  setNewEmail("");
                  toast.success("Email Sent");
                } else {
                  toast.error(response.error);
                }
              }}
              label="Save"
              mr={3}
            ></PrimaryButton>
            <PrimaryButton
              onClick={() => {
                onClose();
                setNewEmail("");
              }}
              label="Cancel"
            ></PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
